<template>
  <base-modal-ex :active.sync="isModelActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="80"
    :min-height="85"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Booking</span>
      </p>
    </header>
    <section class="modal-card-body">
      <div class="tabs is-toggle is-toggle-rounded">
        <ul>
          <li :class="{ 'is-active': isBookingActive }">
            <a @click="tabClicked(tabs[0])">
              <span class="icon is-small">
                <i class="mdi mdi-calendar-month mdi-18px" />
              </span>
              <span>
                <span>Booking</span>
                <span class="has-badge-rounded has-badge-medium"
                  :class="[ false ? 'has-badge-info' : 'has-badge-dark' ]" />
              </span>
            </a>
          </li>
          <li v-if="isRepx"
            :class="{ 'is-active': isRepxActive }">
            <a @click="tabClicked(tabs[1])">
              <span class="icon is-small">
                <i class="mdi mdi-email-open mdi-18px" />
              </span>
              <span>
                <span>Repx</span>
                <span class="has-badge-rounded has-badge-medium"
                  :class="[ false ? 'has-badge-info' : 'has-badge-dark' ]" />
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div v-show="isBookingActive"
        class="quote-header tile is-ancestor is-parent">
        <booking-claim v-if="entity"
          v-model="entity" />
        <booking-vehicle v-if="entity"
          v-model="entity" />
        <booking-customer v-if="entity"
          v-model="entity" />
      </div>
      <!-- <div v-show="isRepairActive"
        class="quote-header is-ancestor is-parent">
        <booking-repair v-if="entity"
          v-model="entity" />
      </div> -->
      <div v-if="isRepxActive"
        class="quote-header is-ancestor is-parent">
        <repx-list :hide-filter="true"
          :quote-booking-id="innerBookingId" />
      </div>
    </section>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="handleDelete()"
      @cancel="isConfirmModalActive = !isConfirmModalActive"
      :ok-text="'Yes'"
      :cancel-text="'No'"
      v-model="entity">
      <p slot="text-title">Delete Booking</p>
      <p slot="text-content">
        Booking
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.vehicleRegistrationNo}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <div class="buttons">
        <button v-if="enableDelete"
          class="button is-danger tooltip"
          data-tooltip="Delete"
          @click="isConfirmModalActive = !isConfirmModalActive">
          <span class="icon">
            <i class="mdi mdi-delete mdi-20px" />
          </span>
          <span>Delete</span>
        </button>

        <button v-if="enableConvert"
          class="button is-warning tooltip"
          data-tooltip="Convert"
          @click="handleConvert">
          <span class="icon">
            <i class="mdi mdi-swap-horizontal mdi-20px" />
          </span>
          <span>Convert</span>
        </button>
        <button v-if="enableSave"
          class="button is-success tooltip"
          data-tooltip="Save"
          @click="handleSave">
          <span class="icon">
            <i class="mdi mdi-content-save mdi-20px" />
          </span>
          <span>Save</span>
        </button>
        <button class="button tooltip"
          @click="handleCancel"
          :data-tooltip="isDeleted ? 'Close' : 'Cancel changes'">
          <span class="icon">
            <i class="mdi mdi-20px"
              :class="isDeleted ? 'mdi-close' : 'mdi-content-save-off'" />
          </span>
          <span>{{ isDeleted ? "Close" : "Cancel" }}</span>
        </button>
        <booking-convert-to-quote v-if="isBookingConvertQuoteActive"
          :active.sync="isBookingConvertQuoteActive"
          :quote-booking-id="innerBookingId"
          :entity="entity"
          v-model="loadOptions"
          @close="closeBookingConvertQuote" />
        <!-- <button class="button tooltip"
          @click="handleClose"
          data-tooltip="Close">
          <span class="icon">
            <i class="mdi mdi-close mdi-20px" />
          </span>
          <span>Close</span>
        </button> -->
      </div>
    </footer>
  </base-modal-ex>

</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { UnsavedModal, ConfirmModal } from '@/components/BulmaModal'
import BookingService from './BookingService'
import BookingValidation from './BookingValidation'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash.clonedeep'
import BookingClaim from './components/BookingHeader/BookingClaim.vue'
// import _debounce from 'lodash.debounce'
import EventBus from '@/components/EventBus'
import { EventHubTypes, QuoteBookingTabs, MessageTypes } from '@/enums'
import BookingCustomer from './components/BookingHeader/BookingCustomer.vue'
import BookingVehicle from './components/BookingHeader/BookingVehicle.vue'
import QuoteRoutes from '@/views/quote/route-types'
import BookingConvertToQuote from './BookingConvertToQuote.vue'
import Guid from '@/components/Guid'
import { CustomerOptionTypes } from '@/components/LoadQuoteSteps'
import RepxList from '../repx/RepxList.vue'

// import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'BookingSimpleViewModal',
  components: {
    BaseModalEx,
    UnsavedModal,
    BookingClaim,
    BookingCustomer,
    BookingVehicle,
    ConfirmModal,
    BookingConvertToQuote,
    RepxList
  },
  mixins: [BookingValidation, StoreMixin],
  props: {
    value: null,
    bookingId: null,
    triggerConvert: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-message-text'
    },
    isNew: Boolean,
    bookingStartDate: null
  },
  data: () => {
    return {
      innerBookingId: '',
      entity: null,
      isModelActive: true,
      isUnsavedModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      enableSave: true,
      enableDelete: true,
      enableConvert: false,
      toRoute: null, // stores "to" route of beforeRouteLeave
      isDeleted: false,
      isConfirmModalActive: false,
      tabs: [QuoteBookingTabs.QuoteBooking, QuoteBookingTabs.Repx],
      selectedTab: QuoteBookingTabs.QuoteBooking,
      isBookingConvertQuoteActive: false,

      loadOptions: {
        messageId: Guid.empty(),
        messageTypeId: MessageTypes.QML_RequestForQuote,
        quoteId: Guid.empty(),
        insurerId: Guid.empty(),
        isCreateQuote: true,
        customerId: Guid.empty(),
        originalCustomerId: Guid.empty(),
        customerOption: CustomerOptionTypes.Create,
        title: '',
        customerName: 'hi',
        firstname: '',
        lastname: '',
        isPerson: false,
        regoNo: '',
        claimNo: '',
        vehicleId: Guid.empty(),
        isOverwriteVehicle: false,
        isOverwriteOrmMessageNo: false,
        isRepx: false
      }
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    },
    isRepx() {
      return this.entity != null && this.entity.isRepx
    },
    isBookingActive() {
      return this.selectedTab == QuoteBookingTabs.QuoteBooking
    },
    isRepairActive() {
      return this.selectedTab == QuoteBookingTabs.Repair && this.isRepairBooked
    },
    isRepairBooked() {
      const value = this.entity != null && this.entity.isRepairBooked != null && eval(this.entity.isRepairBooked) == true
      return eval(value)
    },
    isRepxActive() {
      return this.selectedTab == QuoteBookingTabs.Repx
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    isNew: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.createNew()
        }
      },
      deep: true
    },
    active: {
      handler: function (newVal, oldVal) {
        this.isModelActive = newVal
      },
      deep: true
    },
    // triggerConvert: {
    //   handler: function (newVal, oldVal) {
    //     this.handleConvert()
    //   },
    //   deep: true
    // },
    bookingId: {
      handler: function (newVal, oldVal) {
        this.innerBookingId = newVal
        if (!!this.innerBookingId) {
          this.getEntity(true)
          this.isModelActive = true
          this.isSkipSave = false
        }
      },
      deep: true
    }
    // ,entity: {
    //   handler: _debounce(function (newVal) {
    //     if (newVal) {
    //       this.$forceUpdate()
    //       this.saveSnapshot(_cloneDeep(this.entity))
    //     }
    //   }, 500),
    //   deep: true
    // }
  },
  created() {
    // console.log(QuoteBookingTabs.QuoteBooking)
    this.isModelActive = this.active && !!this.bookingId
    if (!!this.bookingId) this.getEntity()
    // if (this.bookingId == undefined) this.getRepxMessageDetail()
  },
  mounted() {
    //this.isActive = this.active || false
  },
  methods: {
    async createNew() {
      this.isModelActive = false
      this.entity = await this.addStoreItemRoute()
      this.innerBookingId = this.entity.quoteBookingId
      this.getEntity()
      this.entity.isNew = true
      this.isSkipSave = false
      this.isModelActive = true
      this.entity.bookingStartDateTime = this.bookingStartDate
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.innerBookingId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.innerBookingId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)

        const isConvertable = this.entity.quoteId != null || this.entity.isNew
        this.enableConvert = !isConvertable
        this.isDeleted = this.entity.deleted
        this.enableDelete = !this.isDeleted
        // this.enableConvert = !this.isDeleted
        this.enableSave = !this.isDeleted
        if (this.triggerConvert) this.handleConvert()
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|booking|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|booking|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    async save(isDelete) {
      const title = 'Booking Item'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          await BookingService.postBookingItem(this.entity)
        } else if (this.snapshotDiff) {
          await BookingService.putBookingItem(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }
        //await this.removeStoreItem(this.entity.QuoteBookingID)
        this.$notification.success('Booking Item', isDelete ? 'Booking Item set to In-Active' : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        this.$emit('save')
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        // if (e.response.request.status === HttpStatus.CONFLICT) {
        //   this.isSaveConflictModalActive = true
        // } else {
        this.$notification.openMessageXhrError('Booking Item', e)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.entity.isNew) {
        this.isUnsavedModalActive = true
      } else {
        this.close()
      }
    },
    close() {
      this.clearSessionStorage()
      this.clearSnapshots(this.innerBookingId)
      this.entity = null
      this.isUnsavedModalActive = false
      this.isModelActive = false
      this.$emit('close')
    },
    handleSave() {
      this.save(false)
    },
    handleCancel() {
      // console.log(QuoteRoutes.QuoteView.name)
      // const quoteId = '5d426bfd-382a-44f0-bd03-72201e3b94db'
      // var route = QuoteRoutes.QuoteView.path.replace(':quoteId',quoteId )
      // this.$router.push(`${route}`)
      // this.$router.push({
      //   name: QuoteRoutes.QuoteView.name,
      //   params: { quoteId: '5d426bfd-382a-44f0-bd03-72201e3b94db' }
      // })
      if (this.entity.deleted) {
        this.close()
      } else {
        this.cancel()
      }
    },
    handleClose() {
      this.clearSessionStorage()
      this.clearSnapshots(this.innerBookingId)
      this.isModelActive = false
      this.$emit('close')
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    closeModal() {
      this.isUnsavedModalActive = false
      // this.isSaveConflictModalActive = false
      // this.isModelActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },
    async handleDelete() {
      await BookingService.deleteBooking(this.entity)
      this.isConfirmModalActive = false
      this.handleClose()
    },
    async handleConvert() {
      await this.save(false)
      this.isBookingConvertQuoteActive = true
      this.loadOptions.regoNo = this.entity.vehicleRegistrationNo
      this.loadOptions.claimNo = this.entity.claimNo
      this.loadOptions.customerName = this.entity.customerName
      this.loadOptions.isRepx = this.isRepx
      // this.$showSpinner('Saving...')
      // var quote = await BookingService.putConvertBookingToQuote(this.entity)
      // var route = QuoteRoutes.QuoteView.path.replace(':quoteId', quote.data.quoteId)
      // this.$router.push(`${route}`)
      // // this.$router.push({
      // //   name: 'VehicleListView',
      // //   params: { id: quoteId }
      // // })
      // this.$hideSpinner()
      // this.$emit('close')
    },
    async handleConvertQuote() {
      this.isBookingConvertQuoteActive = true

      this.$showSpinner('Saving...')
      var quote = await BookingService.putConvertBookingToQuote(this.entity)
      var route = QuoteRoutes.QuoteView.path.replace(':quoteId', quote.data.quoteId)
      this.$router.push(`${route}`)
      // this.$router.push({
      //   name: 'VehicleListView',
      //   params: { id: quoteId }
      // })
      this.$hideSpinner()
      this.$emit('close')
    },

    tabClicked(selected) {
      this.selectedTab = selected
    },
    closeBookingConvertQuote() {
      this.isBookingConvertQuoteActive = false
      this.triggerConvert = false
    }
  }
}
</script>