// import BookingService from './BookingService'
import BookingRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
//import router from '@/router'
// import { nowDate } from '@/components/utils/DateTimeFunctions'

const tag = 'companyonboarding'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  external: {
    tag: ''
  }
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  getTag: state => state.tag
}

const actions = {
  load({ commit, state }) {

  },
  getStoreItem({ commit }, id) {
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const data = {} //await BookingService.getEntity(id)
      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || {} //(await BookingService.getEntity(id, ''))
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(BookingRoutes.DashboardView.path, entity.quoteBookingId, false)
  },
  addStoreItem({ commit }) {
    const entity = {} //await BookingService.getNewEntity()
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.batchDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(BookingRoutes.DashboardView.path, entity.quoteBookingId, entity.isNew)
  },
  addStoreItemRoute({ commit }) {
    const entity = {} //await BookingService.getNewEntity()
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.batchDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
    return entity
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  updateExternal({ commit }, payload) {
    commit('setExternal', payload)
  }
}

const mutations = {

  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  setExternal(state, payload) {
    state.external = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
