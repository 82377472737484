import BookingService from './BookingService'
import BookingRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
//import router from '@/router'
// import { nowDate } from '@/components/utils/DateTimeFunctions'

const tag = 'booking'

const STORE_INTERGRATIONTYPES = 'STORE_INTERGRATIONTYPES'
const STORE_BOOKINGTYPES = 'STORE_BOOKINGTYPES'
const STORE_REPAIRDELAYREASONS = 'STORE_REPAIRDELAYREASONS'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  messageSummary: {},

  integrationTypes: [],
  repairDelayReasons: [],
  bookingTypes: []

}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  messageSummary: state => state.messageSummary,
  integrationTypes: state => state.integrationTypes,
  bookingTypes: state => state.bookingTypes,
  repairDelayReasons: state => state.repairDelayReasons
}

const actions = {
  load({ commit, state }) {
    if (state.integrationTypes.length === 0) {
      commit(STORE_INTERGRATIONTYPES, [
        { id: '1', name: 'REPX' }
      ])
    }
    if (state.bookingTypes.length === 0) {
      commit(STORE_BOOKINGTYPES, [
        { id: '1', name: 'INSPECT' },
        { id: '2', name: 'REPAIR' }
      ])
    }
    if (state.repairDelayReasons.length === 0) {
      commit(STORE_REPAIRDELAYREASONS, [
        { id: 'NONE', name: 'NONE' },
        { id: 'PARTS_DELAY', name: 'PARTS DELAY' },
        { id: 'REPAIR_LONGER_THAN_EXPECTED', name: 'REPAIR LONGER THAN EXPECTED' },
        { id: 'ADDITIONAL_DAMAGE', name: 'ADDITIONAL DAMAGE' },
        { id: 'WAITING_ON_ASSESSMENT', name: 'WAITING ON ASSESSMENT' },
        { id: 'OTHER', name: 'OTHER' }
      ])
    }
  },
  async getStoreItem({ commit }, id) {
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const data = await BookingService.getEntity(id)
      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await BookingService.getEntity(id, ''))
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(BookingRoutes.DashboardView.path, entity.quoteBookingId, false)
  },
  async addStoreItem({ commit }) {
    const entity = await BookingService.getNewEntity()
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.batchDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(BookingRoutes.DashboardView.path, entity.quoteBookingId, entity.isNew)
  },
  async addStoreItemRoute({ commit }) {
    const entity = await BookingService.getNewEntity()
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.batchDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.quoteBookingId, tag, entity)
    commit('saveInitialSnapshots', entity)
    return entity
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  [STORE_INTERGRATIONTYPES](state, data) {
    state.integrationTypes = data
  },

  [STORE_BOOKINGTYPES](state, data) {
    state.bookingTypes = data
  },

  [STORE_REPAIRDELAYREASONS](state, data) {
    state.repairDelayReasons = data
  },

  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
