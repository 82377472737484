<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="tile is-parent">
      <div class="tile is-child box detail-page-tile">
        <repx-list :table-box-css-on="true" />
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '../../components/AppDetailHeader/AppDetailHeader.vue'
import RepxList from './RepxList.vue'
export default {
  name: 'RepxListView',
  components: { RepxList, AppDetailHeader },
  props: {
    value: null
  },
  data: () => {
    return {
      id: '',
      entities: {},
      isModelActive: false
    }
  },
  watch: {
    // visible: {
    //   handler: function (newVal, oldVal) {
    //     this.isModelActive = newVal
    //   },
    //   deep: true
    // },
  },
  created() {
    //if (this.messageId == undefined) this.getRepxMessageDetail()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    }
    // async getRepxMessageDetail() {
    //   this.entities = await RepxService.getWebServiceDetail(this.id)
    // },
    // hanldeClose() {
    //   this.isModelActive = false
    //   this.$emit('close')
    // }
  }
}
</script>
