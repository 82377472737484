<template>
  <section class="hero is-primary hero-header has-sticky-module-header">
    <div class="hero-body is-paddingless">
      <nav class="navbar">
        <div class="navbar-brand">
          <div class="navbar-item is-hidden-desktop">
            <div class="field is-grouped">
              <p class="control">
                <a id="toggle"
                  class="button tooltip is-tooltip is-tooltip-topleft is-primary"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="toggleSide"
                  title="Toggle Side Menu"
                  data-tooltip="Toggle Side Menu">
                  <span class="icon">
                    <!-- <i class="mdi mdi-menu" /> -->
                    <span class="msr">menu</span>
                  </span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isDelete && !serviceError && showCalendar"
                class="control">
                <a id="calendar"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="calendar"
                  title="Calendar"
                  data-tooltip="Calendar">
                  <span class="icon">
                    <i class="mdi mdi-calendar" />
                  </span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isAdd && !serviceError && showNew"
                class="control">
                <a id="addnew"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="addNew"
                  title="Add New"
                  data-tooltip="Add New">
                  <span class="icon">
                    <span class="msr">add</span>
                  </span>
                </a>
              </p>
              <p v-if="access.isPrint && !serviceError && showPrint"
                class="control">
                <a id="print"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="print"
                  title="Print"
                  data-tooltip="Print">
                  <span class="icon">
                    <span class="msr">print</span>
                  </span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isDelete && !serviceError && showDelete"
                class="control">
                <a id="delete"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="trash"
                  title="Delete"
                  data-tooltip="Delete">
                  <span class="icon">
                    <span class="msr">delete</span>
                  </span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isEdit && !serviceError && showSave"
                class="control">
                <a id="save"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="save"
                  title="Save"
                  data-tooltip="Save">
                  <span class="icon">
                    <span class="msr">save</span>
                  </span>
                </a>
              </p>
              <p v-if="showCancel"
                class="control">
                <a id="cancel"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="cancel"
                  title="Go Back"
                  data-tooltip="Go Back">
                  <span class="icon">
                    <span class="msr">arrow_back</span>
                  </span>
                </a>
              </p>
              <p v-if="showLock"
                class="control">
                <a id="lock"
                  class="button tooltip is-tooltip-topleft"
                  :class="buttonCSS"
                  @click="toggleReadOnly"
                  title="Lock Manage"
                  data-tooltip="Lock Manage">
                  <span class="icon">
                    <span class="msr">{{ entity.readOnly ? 'lock' : 'lock_open' }}</span>
                  </span>
                </a>
              </p>
            </div>
          </div>

          <div class="navbar-burger burger"
            data-target="action-button-menu">
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="detail-nav-menu"
          class="navbar-start is-hidden-touch">
          <div class="navbar-item">
            <h1 class="title">
              <a class="button is-primary is-outlined"
                v-if="showToggle"
                :class="buttonCSS"
                :disabled="!buttonsEnabled"
                @click="toggleSide">
                <span class="icon">
                  <span class="msr msr-24px">menu</span>
                </span>
              </a>
              <span v-for="(route, idx) in $route.matched.slice()"
                :key="idx">
                <span v-if="idx > 0 && route.meta.title"
                  style="transform: translateY(0.5rem);"
                  class="icon">
                  <span class="msr msr-36px">chevron_right</span>
                </span>
                <span>
                  {{ title || route.meta.title }}
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="navbar-end is-hidden-touch">
          <div class="navbar-item">
            <div class="field is-grouped">

              <p v-if="!licenceExpired && access.isAdd && !serviceError && showCalendar"
                class="control">
                <a id="calendar"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="calendar">
                  <span class="icon">
                    <i class="mdi mdi-calendar mdi-18px" />
                  </span>
                  <span>Calendar</span>
                </a>
              </p>

              <p v-if="!licenceExpired && access.isAdd && !serviceError && showNew"
                class="control">
                <a id="addnew"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="addNew">
                  <span class="icon">
                    <span class="msr msr-18px">add</span>
                  </span>
                  <span>New</span>
                </a>
              </p>

              <p v-if="access.isPrint && !serviceError && showPrint && false"
                class="control">
                <a id="preview"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="print">
                  <span class="icon">
                    <span class="msr msr-18px">print</span>
                  </span>
                  <span>Preview</span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isDelete && !serviceError && showDelete && false"
                class="control">
                <a id="delete"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="trash">
                  <span class="icon">
                    <span class="msr msr-18px">delete</span>
                  </span>
                  <span>Delete</span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isEdit && !serviceError && showConvert"
                class="control">
                <a id="convert"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="convert">
                  <span class="icon">
                    <i class="mdi mdi-swap-horizontal mdi-18px" />
                  </span>
                  <span>Convert</span>
                </a>
              </p>
              <p v-if="!licenceExpired && access.isEdit && !serviceError && showSave"
                class="control">
                <a id="save"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="save">
                  <span class="icon">
                    <span class="msr msr-18px">save</span>
                  </span>
                  <span>Save</span>
                </a>
              </p>
              <p v-if="showCancel"
                class="control">
                <a id="cancel"
                  class="button"
                  :class="buttonCSS"
                  :disabled="!buttonsEnabled"
                  @click="cancel">
                  <span class="icon">
                    <span class="msr msr-18px">arrow_back</span>
                  </span>
                  <span>Back</span>
                </a>
              </p>
              <div v-show="showDelete || showPrint || showLock || showQuoteTemplate"
                name="headerMoreButtons"
                class="button is-primary px-2"
                @mouseenter="handleBurgerButton">
                <span class="icon">
                  <span class="msr msr-36px">more_vert</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <tippy ref="tippyHeaderButtons"
      to="headerMoreButtons"
      trigger="click"
      arrow
      :interactive="true"
      theme="light"
      :max-width="500">
      <div class="menu more-menu">
        <ul class="menu-list">
          <li v-if="access.isPrint && !serviceError && showPrint">
            <a id="preview"
              class="is-flex is-align-items-center"
              @click="print">
              <span class="icon pr-3">
                <span class="msr msr-20px">print</span>
              </span>
              <span>Preview</span>
            </a>
          </li>
          <li v-if="!licenceExpired && !serviceError && showLock">
            <a class="is-flex is-align-items-center"
              id="lock"
              @click="toggleReadOnly()">
              <span class="icon pr-3">
                <span class="msr msr-20px">{{ entity.readOnly ? 'lock' : 'lock_open' }}</span>
              </span>
              <span>{{ entity.readOnly ? 'Unlock' : 'Lock' }}</span>
            </a>
          </li>
          <li v-if="!licenceExpired && !serviceError && showQuoteTemplate">
            <div v-if="!quoteTemplateDisabled">
              <a id="quoteTemplate"
                class="is-flex is-align-items-center"
                @click="applyQuoteTemplate">
                <span class="icon pr-3">
                  <span class="msr msr-20px">add</span>
                </span>
                <span>Repairer Quote Template</span>
              </a>
            </div>
            <div v-else>
              <button class="is-flex is-align-items-center button is-white
                is-fullwidth pl-0 is-justify-content-flex-start"
                id="quoteTemplate"
                :disabled="quoteTemplateDisabled">
                <span class="icon ml-0">
                  <span class="msr msr-20px">print</span>
                </span>
                <span>Repairer Quote Template</span>
              </button>
            </div>
          </li>
          <li v-if="!serviceError && showPricePartsMsg">
            <a id="priceparts"
              class="is-flex is-align-items-center"
              @click="viewPricePartsMsg">
              <span class="icon pr-3">
                <span class="msr msr-20px">chat</span>
              </span>
              <span>View Price Parts Messages</span>
            </a>
          </li>

          <div v-if="!licenceExpired && access.isDelete && !serviceError && showDelete && showPrint"
            class="is-divider" />
          <li v-if="!licenceExpired && access.isDelete && !serviceError && showDelete">
            <a class="has-text-danger is-flex is-align-items-center"
              id="delete"
              @click="trash">
              <span class="icon pr-3">
                <span class="msr msr-20px">delete</span>
              </span>
              <span>Delete</span>
            </a>
          </li>
        </ul>
      </div>
    </tippy>
  </section>
</template>

<script>
import HttpStatus from '@/components/http-status'
import EventBus from '@/components/EventBus'
import { mapGetters } from 'vuex'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'

export default {
  name: 'AppDetailHeader',
  components: {},
  props: {
    entity: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    showNew: {
      type: Boolean,
      default: true
    },
    showCalendar: {
      type: Boolean,
      default: false
    },
    showPrint: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showSave: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showToggle: {
      type: Boolean,
      default: false
    },
    buttonsEnabled: {
      type: Boolean,
      default: true
    },
    showLock: {
      type: Boolean,
      default: false
    },
    value: null,
    showPricePartsMsg: {
      type: Boolean,
      default: false
    },
    showQuoteTemplate: {
      type: Boolean,
      default: false
    },
    quoteTemplateDisabled: {
      type: Boolean,
      default: true
    },
    applyRepairerTemplate: {
      type: Boolean,
      default: false
    },
    showConvert: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      serviceError: false,
      access: {},
      showSideMenu: true
    }
  },
  computed: {
    buttonCSS: function () {
      return 'is-inverted'
    },
    ...mapGetters(['licenceExpired'])
  },
  watch: {
    $route(to, from) {
      this.setAccess()
    }
  },
  created() {
    EventBus.$on('serviceError', (data) => {
      this.handleBusEvent(data)
    })
    this.$eventHub.$on(EventHubTypes.AutoHideSideMenu, (data) => {
      this.handleSideMenuEvent(data)
    })
  },
  mounted() {
    this.setAccess()
  },
  methods: {
    addNew() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.AddNew)
    },
    print() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Print)
    },
    trash() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Delete)
    },
    save() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Save)
    },
    cancel() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Cancel)
    },
    toggleSide() {
      this.showSideMenu = !this.showSideMenu
      this.$eventHub.$emit(EventHubTypes.ToggleSideMenu, this.showSideMenu)
    },
    handleBusEvent(data) {
      this.serviceError = data === HttpStatus.NOT_FOUND
    },
    handleSideMenuEvent(data) {
      this.showSideMenu = !data
    },
    toggleReadOnly() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Lock)
    },
    viewPricePartsMsg() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.PriceParts)
    },
    handleBurgerButton() {
      if (this.showQuoteTemplate) {
        this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.PreApplyQuoteTemplateCheck)
      }
    },
    applyQuoteTemplate() {
      if (!this.quoteTemplateDisabled) {
        this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.ApplyQuoteTemplate)
      }
    },
    calendar() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Calendar)
    },
    convert() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Convert)
    },
    setAccess() {
      if (this.$route && this.$route.meta) {
        if (this.$route.meta.access)
          this.access = this.$route.meta.access
        else
          this.access = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-list a:hover {
  background-color: hsl(0, 0%, 90%) !important;
}
.menu.more-menu {
  min-width: 15rem !important;
}
.is-divider {
  margin: 0.5rem 0;
}
</style>
