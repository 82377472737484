import AuthRoutes from './route-types.js'

const ForgotPasswordView = () => import('./ForgotPassword.vue')
const ForgotPasswordCsrView = () => import('./ForgotPasswordCsr.vue')
const ResetPasswordView = () => import('./ResetPassword.vue')
const ForgotPasswordLogListView = () => import('./ForgotPasswordLogListView.vue')

export default [
  {
    path: AuthRoutes.ForgotPasswordView.path,
    name: AuthRoutes.ForgotPasswordView.name,
    component: ForgotPasswordView,
    meta: { bypassAuth: true }
  },
  {
    path: AuthRoutes.ForgotPasswordCsrView.path,
    name: AuthRoutes.ForgotPasswordCsrView.name,
    component: ForgotPasswordCsrView,
    meta: { bypassAuth: true }
  },
  {
    path: AuthRoutes.ResetPasswordView.path,
    name: AuthRoutes.ResetPasswordView.name,
    component: ResetPasswordView,
    meta: { bypassAuth: true}
  },
  {
    path: AuthRoutes.ForgotPasswordLogListView.path,
    name: AuthRoutes.ForgotPasswordLogListView.name,
    component: ForgotPasswordLogListView,
    meta: {
      route: 'listing',
      title: 'Forgot Password Log',
      name: 'Forgot Password Log'
    }
  }
]