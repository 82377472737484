<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="true"
      :show-print="false"
      :show-calendar="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <booking-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="columns"
              v-if="isRepxEnabled"
              style="margin-left: 0rem">
              <div class="field">
                <v-date-picker v-model="pullDate"
                  :masks="formats"
                  :attributes="attrs"
                  :model-config="modelConfig"
                  :locale="$userInfo.locale"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Date..."
                          :value="inputValue"
                          v-on="inputEvents">
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
                <p>Select date to fetch data from RepX</p>
              </div>
              <div class="field is-grouped is-pulled-right">
                <div class="control">
                  <button class="button is-primary"
                    @click="onPullClick">
                    <span class="icon">
                      <i class="mdi mdi-cloud-download mdi-18px" />
                    </span>
                    <span>Fetch</span>
                  </button>
                </div>
              </div>
            </div>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(booking, index) in entities"
                :key="booking.quoteBookingID"
                :class="{ 'is-selected': selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <!-- <a @click="handleSimpleEdit(booking.quoteBookingID)">
                    {{ booking.vehicleRegistrationNo }}
                  </a> -->
                  <router-link :to="{ path: `/booking/${booking.quoteBookingID}` }">
                    {{ booking.vehicleRegistrationNo }}
                  </router-link>
                </td>
                <!-- <td>{{ booking.vehicleMake }}</td>
                <td>{{ booking.vehicleModel }}</td> -->
                <td>{{ booking.claimNo }}</td>
                <td>
                  <a class="button is-fullwidth"
                    :class="[booking.quoteId != null ? 'is-primary' : 'is-success']"
                    @click="booking.quoteId != null && handleQuoteClick(booking.quoteId)">
                    {{ booking.quoteId != null ? booking.quoteNo : 'NEW' }}
                  </a>
                </td>
                <td>{{ booking.customerName }}</td>
                <td class="has-text-right">{{ booking.excess | formatCurrency($userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimeLocale(booking.bookingStartDateTime, $userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimeLocale(booking.bookingEndDateTime, $userInfo.locale) }}</td>
                <td>{{ $filters.formatDateLocale(booking.createdDate, $userInfo.locale) }}</td>
                <!-- <td>{{ booking.bookingStatus | formatBatchStatus }}</td> -->
                <td class="has-text-centered">{{ booking.deleted ? 'Yes' : 'No' }}</td>
                <td class="has-text-centered">
                  <router-link :to="{ path: `${booking.quoteBookingID}` }"
                    append>
                    <a class="button is-primary is-small is-inverted tooltip"
                      data-tooltip="Click to edit">
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </a>
                  </router-link>
                  <!-- <a
                    v-if="booking.quoteId == null"
                    class="button is-success is-small is-inverted tooltip"
                    data-tooltip="Convert to Quote"
                    @click="editConvertToQuote(booking.quoteBookingID)"
                  >
                    <span class="icon is-medium">
                      <i class="mdi mdi-download mdi-24px" />
                    </span>
                  </a> -->
                  <!-- <a class="button is-primary is-small is-inverted tooltip"
                    data-tooltip="Click to edit"
                    @click="handleEdit(booking.quoteBookingID)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a> -->
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
            <!--
            <router-link class="button is-success"
              to="/bookingCalendar"
              append>
              <span class="icon">
                <i class="mdi mdi-open-in-app mdi-18px" />
              </span>
              <span>Go to Calendar</span>
            </router-link>

            <button class="button is-success ml-1"
              @click="handleNew">
              <span class="icon">
                <i class="mdi mdi-plus mdi-18px" />
              </span>
              <span>New</span></button> -->
          </article>
        </div>
      </div>
    </div>
    <!-- <booking-modal v-if="isBookingModalActive"
      :active.sync="isBookingModalActive"
      :booking-id="selectedBookingId"
      @close="closeBookingModal" /> -->
    <booking-simple-view-modal :active.sync="isSimpleBookingModelActive"
      :booking-id="selectedEditBookingId"
      :visible="isSimpleBookingModelActive"
      :is-new="isNewSimpleBooking"
      :trigger-convert="triggerConvert"
      @close="handleSimpleBookingModalClosed"
      @save="handleSimpleBookingSave" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import BookingListSideMenu from './BookingListSideMenu'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { AppHeaderButtonTypes } from '@/enums'
import BookingService from './BookingService'
// import { OrmBatchStatusType } from '@/enums'
import StoreMixin from './storeMixin'
// import BookingModal from './BookingModal.vue'
import BookingSimpleViewModal from './BookingSimpleViewModal.vue'
import QuoteRoutes from '@/views/quote/route-types'
import BookingRoutes from './route-types'
import RepxService from '@/views/repx/RepxService'

export default {
  name: 'BookingListView',
  components: {
    AppDetailHeader,
    BookingListSideMenu,
    BulmaTable,
    // BookingModal,
    BookingSimpleViewModal
  },
  // filters: {
  //   formatBatchStatus(value) {
  //     const index = Object.values(OrmBatchStatusType).indexOf(value)
  //     const status = Object.keys(OrmBatchStatusType)[index]
  //     return status.replace(/([a-z])([A-Z])/g, '$1 $2')
  //         .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
  //         .replace(/^./, function(str) {
  //           return str.toUpperCase()
  //         })
  //   }
  // },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {},
  data: () => {
    return {
      isTableLoading: false,
      totalRows: 0,
      selectedRow: null,
      filter: {
        claimNo: null,
        vehicleNo: null,
        fromDate: null,
        toDate: null,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18,
        taskStatus: '',
        taskType: ''
      },
      entities: null,
      isBookingModalActive: false,
      selectedBookingId: null,
      selectedEditBookingId: null,
      isSimpleBookingModelActive: false,
      isNewSimpleBooking: false,

      triggerConvert: false,
      pullDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        timeAdjust: '00:00:00'
      },
      isRepxEnabled: false
    }
  },
  computed: {
    columns() {
      return Columns
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    }
  },
  watch: {},
  async created() {
    this.getBookingList()
    await this.checkIsRepxEnable()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
        case AppHeaderButtonTypes.Calendar:
          this.$router.push(BookingRoutes.CalendarView.path)
          break
      }
    },
    highlightSelected(index, event) {},
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.refresh()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.refresh()
    },
    filterList() {
      this.filter.pageIndex = 1
      this.refresh()
    },
    resetList() {
      this.filter.vehicleNo = null
      this.filter.fromDate = null
      this.filter.toDate = null
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.filter.taskStatus = ''
      this.filter.taskType = ''
      this.refresh()
    },
    refresh() {
      this.persistFilter()
      this.getBookingList()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async getBookingList() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entities = await BookingService.getBookingList(this.filter)
      console.log(this.filter)
      this.totalRows = this.entities.length > 0 ? this.entities[0].totalRows : 0
      this.isTableLoading = false
    },
    async addNew() {
      this.addStoreItem()
    },
    closeBookingModal() {
      this.isBookingModalActive = false
    },
    onEditBooking() {
      this.isBookingModalActive = true
    },
    handleEdit(bookingId) {
      this.selectedBookingId = bookingId
      this.isBookingModalActive = true
      this.isSimpleBookingModelActive = false
    },
    handleSimpleEdit(bookingId) {
      this.selectedEditBookingId = bookingId
      this.isNewSimpleBooking = false
      this.isSimpleBookingModelActive = true
    },
    handleSimpleBookingModalClosed() {
      this.selectedEditBookingId = null
      this.isSimpleBookingModelActive = false
      this.isNewSimpleBooking = false
      this.triggerConvert = false
      this.refresh()
    },
    handleNew() {
      this.selectedEditBookingId = null
      this.isNewSimpleBooking = true
      this.isSimpleBookingModelActive = true
    },
    handleSimpleBookingSave() {
      this.getBookingList()
    },
    handleQuoteClick(quoteId) {
      var route = QuoteRoutes.QuoteView.path.replace(':quoteId', quoteId)
      this.$router.push(`${route}`)
    },
    editConvertToQuote(bookingId) {
      this.selectedEditBookingId = bookingId
      this.isNewSimpleBooking = false
      this.isSimpleBookingModelActive = true
      this.triggerConvert = true
    },
    async onPullClick() {
      this.$showSpinner()
      await BookingService.getInspectionBooking({ pullDate: this.pullDate })
      //await BookingService.getAllocationBooking({ pullDate: this.pullDate })
      this.getBookingList()
      this.$hideSpinner()
    },
    async checkIsRepxEnable() {
      const isRepxEnabled = await RepxService.getIsRepxEnable()
      this.isRepxEnabled = isRepxEnabled
    }
  }
}
</script>
