import { mapGetters, mapActions } from 'vuex'

const moduleName = 'booking'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    })
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem','addStoreItemRoute', 'saveSnapshot', 'clearSnapshots', 'setReturnRoute']),
    ...mapActions('quotes', { setQuoteReturnRoute: 'setReturnRoute', editStoreQuote: 'editStoreItem' })
  }
}
