<template>
  <div v-if="value"
    class="tile is-parent"
    :class="{'box  ml-2': showBox}">
    <article class="tile is-child is-parent">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center">
          <i class="icon mdi mdi-car mdi-24px" />
          <p class="modal-card-title pl-1">Vehicle</p>
        </div>
      </div>
      <div class="is-divider" />
      <div class="tile is-ancestor ">
        <div class="tile">
          <div class="tile is-parent is-half">
            <article class="tile is-child detail-page-tile"
              :class="{'disabled' : (value.quoteId) }"
            >
              <div class="field">
                <label class="label is-required-label">Registration No. </label>
                <div class="control">
                  <input v-model="value.vehicleRegistrationNo"
                    class="input"
                    :class="{ 'is-danger' : $v.entity.vehicleRegistrationNo.$error}"
                    type="text">
                  <span class="help is-danger"
                    v-if="$v.entity && !$v.entity.vehicleRegistrationNo.required">Registration No. is required</span>
                </div>
              </div>
              <div class="field">
                <label class="label">Make</label>
                <div class="control">
                  <input v-model="value.vehicleMake"
                    class="input"
                    type="text">
                </div>
              </div>
              <div class="field">
                <label class="label">Model</label>
                <div class="control">
                  <input v-model="value.vehicleModel"
                    class="input"
                    type="text">
                </div>
              </div>
              <div class="field">
                <label class="label">Year</label>
                <div class="control">
                  <input v-model="value.vehicleYear"
                    class="input"
                    type="number">
                </div>
              </div>
              <div class="columns field">
                <!-- <div class="column">
                  <div class="field">
                    <label class="label">Is Inspection Booked?</label>
                  </div>
                  <div class="field">
                    <bulma-checkbox :disabled="true"
                      v-model="value.isInspectionBooked"
                      @change="hanldeInspectionBookedChange" />
                  </div>
                </div> -->
                <div class="column"
                  v-if="isInspectionBooked">
                  <div class="field">
                    <label class="label">Is Arrive For Inspection?</label>
                  </div>
                  <div class="field">
                    <bulma-checkbox-date v-model="value.isArriveForInspection"
                      :date="value.arriveForInspectionSyncedDate" />
                  </div>
                </div>
              </div>
              <div class="columns field" />
              <div class="field"
                v-if="isArriveForInspection">
                <label class="label">Arrive For Inspection Date</label>
                <v-date-picker v-model="value.arriveForInspectionDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :min-date="minDate"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Arrive For Inspection Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          :disabled="value.arriveForInspectionSyncedDate != null"
                        >
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <!-- <div class="columns field"
                v-if="isArriveForInspection">
                <div class="column">
                  <div class="field">
                    <label class="label">Is Repair Booked?</label>
                  </div>
                  <div class="field">
                    <bulma-checkbox-date v-model="value.isRepairBooked"
                      :class="{ 'is-danger' : $v.entity.isRepairBooked.$error }"
                      @change="hanldeRepairBookedChange" />
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.isRepairBooked.required">Is Repair Booked is required</span>
                  </div>

                  <div class="field"
                    v-if="!isRepairBooked">
                    <label class="label">Refuse Book Repair Reason</label>
                    <div class="control">
                      <textarea class="textarea"
                        v-model="value.refuseBookRepairReason"
                        :class="{ 'is-danger' : $v.entity.refuseBookRepairReason.$error }"
                        placeholder="Add reason here"
                        v-focus-inserted />
                      <span class="help is-danger"
                        v-if="$v.entity && !$v.entity.refuseBookRepairReason.required">Refuse Book Repair Reason is required</span>
                    </div>
                  </div>
                </div>
              </div> -->
            </article>
          </div>
        </div>
      </div>
    </article>
  </div>

</template>

<script>
// import QuickItemService from './QuickItemService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'
import storeMixin from '../../storeMixin'
// import BulmaCheckbox from '../../../../components/BulmaCheckbox/BulmaCheckbox.vue'
import BulmaCheckboxDate from '../../../../components/BulmaCheckbox/BulmaCheckboxDate.vue'

export default {
  name: 'BookingVehicle',
  inject: ['$vv'],
  components: {
    // BulmaCheckbox,
    BulmaCheckboxDate
    // QuickItemService,
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, storeMixin],
  props: {
    value: null,
    isNew: Boolean,
    showBox: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        start: {
          timeAdjust: '08:00:00'
        },
        end: {
          timeAdjust: '08:00:00'
        }
      }
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    minDate() {
      const today = new Date()
      return today
    },
    isArriveForInspection() {
      const isArriveForInspection = (this.value && this.value.isArriveForInspection === 'true') || this.value.isArriveForInspection === true
      return isArriveForInspection
    },
    isInspectionBooked() {
      const isInspectionBooked = (this.value && this.value.isInspectionBooked === 'true') || this.value.isInspectionBooked === true
      return isInspectionBooked
    },
    isRepairBooked() {
      const isRepairBooked = (this.value && this.value.isRepairBooked === 'true') || this.value.isRepairBooked === true
      return isRepairBooked
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    toggleActive(deleted) {
      this.value.deleted = deleted
    },
    clear(input) {
      const inputEle = this.$refs[input]
      if (inputEle) {
        this.value[input] = ''
      }
    },
    clearInputs(inputs, value) {
      if (value === 'false' || value == false || value === '' || value == null) {
        inputs.forEach((input) => {
          const key = Object.keys(input)[0]
          if (this.value.hasOwnProperty(key)) {
            this.value[key] = input[key]
          } else {
            console.warn(`Key ${key} does not exist in value object`)
          }
        })
      }
    },
    hanldeInspectionBookedChange() {
      const inputs = [
        { isArriveForInspection: '' },
        { arriveForInspectionDate: null },
        { isRepairBooked: '' },
        { isArriveForRepair: '' },
        { refuseBookRepairReason: '' },
        { arriveForRepairDate: null },
        { repairStartDate: null },
        { repairEndDate: null },
        { repairLateReason: '' },
        { repairLateReasonDetails: '' }
      ]
      this.clearInputs(inputs, this.value.isInspectionBooked)
    },
    hanldeIsArriveForInspectionChange() {
      const inputs = [
        { arriveForInspectionDate: null },
        { isRepairBooked: '' },
        { isArriveForRepair: '' },
        { refuseBookRepairReason: '' },
        { refuseBookRepairReason: '' },
        { arriveForRepairDate: null },
        { repairStartDate: null },
        { repairEndDate: null },
        { repairLateReason: '' },
        { repairLateReasonDetails: '' }
      ]
      this.clearInputs(inputs, this.value.isArriveForInspection)
    },
    hanldeRepairBookedChange() {
      // const inputs = [
      //   'isArriveForRepair',
      const inputs = [
        { isArriveForRepair: '' },
        { refuseBookRepairReason: '' },
        { arriveForRepairDate: null },
        { repairStartDate: null },
        { repairEndDate: null },
        { repairLateReason: '' },
        { repairLateReasonDetails: '' }
      ]
      this.clearInputs(inputs, this.value.isRepairBooked)
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 0 0 1.2rem 0;
}
.desc-width {
  width: 50%;
}
article.tile.is-child > .columns .column i.icon{
  color: #1A5276;
}
.modal-card-title{
  color: #1A5276;
    font-weight: 600;
    line-height: 1.125;
}
div.tile.is-parent {
  min-height: calc(100vh - 18rem);
}
</style>
