import SubJobStageRoutes from './route-types'
import SubJobStageListView from './SubJobStageListView.vue'
import store from '@/store'

const SubJobStageView = r => require.ensure([], () => r(require('./SubJobStageView.vue')), 'subjobstage-chunk')
const SubJobStageDetail = r => require.ensure([], () => r(require('./SubJobStageDetail.vue')), 'subjobstage-chunk')
const moduleId = 'A230'
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|SubJobStage|${query}`)
}
export default [
  {
    path: SubJobStageRoutes.SubJobStageListView.path,
    name: SubJobStageRoutes.SubJobStageListView.name,
    component: SubJobStageListView,
    meta: {
      id: moduleId,
      // route: 'listing',
      title: 'Sub Job Stage'
    }
  },
  {
    path: SubJobStageRoutes.SubJobStageView.path,
    name: '',
    component: SubJobStageView,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Sub Job Stage'
    },
    children: [
      {
        path: '',
        name: SubJobStageRoutes.SubJobStageDetail.name,
        component: SubJobStageDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail'
        }
      }
    ]
  }
]
