import BookingRoutes from './route-types'
import store from '@/store'

import BookingListView from './BookingListView.vue'
// import RepxComponent from './RepxComponent.vue'
import BookingCalendar from './BookingCalendar.vue'

const BookingHeader = () => import(/* webpackChunkName: "booking-header-chunk" */ './BookingHeader.vue')
const BookingView = (r) => require.ensure([], () => r(require('./BookingView.vue')), 'booking-detail-chunk')
const BookingRepx = (r) => require.ensure([], () => r(require('./BookingRepx.vue')), 'booking-repx-chunk')

const moduleId = 'A220'

// Get sessionStorage
const tag = 'Booking'
const getStorage = function (query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|${tag}|${query}`)
}

export default [
  {
    path: BookingRoutes.DashboardView.path,
    name: BookingRoutes.DashboardView.name,
    component: BookingListView,
    meta: {
      id: moduleId,
      title: 'Bookings',
      fkey: 'Booking|filter'
    }
  },
  {
    path: BookingRoutes.CalendarView.path,
    name: BookingRoutes.CalendarView.name,
    component: BookingCalendar,
    meta: {
      // id: moduleId,
      title: 'Booking Calendar'
    }
  },
  {
    path: BookingRoutes.BookingView.path,
    name: '',
    component: BookingView,
    props: (route) => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: moduleId,
      route: 'header',
      title: 'Booking',
      isNew: false,
      ekey: 'emailer'
    },
    children: [
      {
        path: '',
        name: BookingRoutes.BookingHeader.name,
        component: BookingHeader,
        meta: {
          id: moduleId,
          route: 'header',
          title: 'Details'
        }
      },
      {
        path: BookingRoutes.BookingRepx.path,
        name: BookingRoutes.BookingRepx.name,
        component: BookingRepx,
        meta: {
          id: moduleId,
          route: 'repx',
          title: 'Repx'
        }
      }
    ]
  }
]
