import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Job Stage', 'jobStageName', true, 'asc', 'left'),
  new TableColumn('Sub Job Stage', 'subJobStageName', true, 'asc', 'left'),
  new TableColumn('Sort Number', 'sortKey', true, 'asc', 'left'),
  new TableColumn('Active', 'isActive', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
