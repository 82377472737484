<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input"
                v-model="innerValue.repairQuoteId"
                type="text"
                placeholder="Repair Quote ID"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input"
                v-model="innerValue.quoteNo"
                type="text"
                placeholder="Quote No."
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <!-- <div class="field">
            <v-date-picker v-model="selectedBatchDate"
              :masks="formats"
              popover.visibility="focus"
              :attributes="attrs"
              :locale="$userInfo.locale">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div> -->
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :model-config="modelConfig"
              :locale="$userInfo.locale"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :model-config="modelConfig"
              :locale="$userInfo.locale"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <!-- <div class="field is-expanded">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.batchStatus">
                  <option v-for="(status, propertyName) in ormBatchStatusType"
                    :value="status"
                    :key="status">
                    {{ propertyName | formatOrmBatchStatus }}
                  </option>
                </select>
              </div>
            </div>
          </div> -->
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
// import { OrmBatchStatusType } from '@/enums'
import RepxMixin from '@/views/repx/storeMixin'

export default {
  name: 'RepxListSideMenu',
  components: {
    AppSideMenu
  },
  filters: {},
  mixins: [RepxMixin],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: this.value,
      // selectedBatchDate: null,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        timeAdjust: '00:00:00'
      }
    }
  },
  computed: {
    // ormBatchStatusType() {
    //   return OrmBatchStatusType
    // }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    },
    // selectedBatchDate(newVal, oldVal) {
    //   if (newVal) {
    //     this.innerValue.batchDate = newVal.toISOString().split('.')[0] + 'Z'
    //   } else {
    //     this.innerValue.batchDate = null
    //   }
    // },
    selectedFromDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.fromDate = newVal.toISOString()
      } else {
        this.innerValue.fromDate = null
      }
    },
    selectedToDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.toDate = newVal.toISOString()
      } else {
        this.innerValue.toDate = null
      }
    }
  },
  created() {
    // if (this.value.batchDate) {
    //   this.selectedBatchDate = new Date(this.value.batchDate)
    // }
    if (this.value.fromDate) {
      this.selectedFromDate = new Date(this.value.fromDate)
    }
    if (this.value.toDate) {
      this.selectedToDate = new Date(this.value.toDate)
    }
  },
  mounted() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      // this.selectedBatchDate = null
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
