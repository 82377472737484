/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ForgotPasswordFilter = (function() {

    /**
     * Namespace ForgotPasswordFilter.
     * @exports ForgotPasswordFilter
     * @namespace
     */
    var ForgotPasswordFilter = {};

    ForgotPasswordFilter.ForgotPasswordFilterModel = (function() {

        /**
         * Properties of a ForgotPasswordFilterModel.
         * @memberof ForgotPasswordFilter
         * @interface IForgotPasswordFilterModel
         * @property {string|null} [sortColumn] ForgotPasswordFilterModel sortColumn
         * @property {string|null} [sortOrder] ForgotPasswordFilterModel sortOrder
         * @property {number|null} [pageIndex] ForgotPasswordFilterModel pageIndex
         * @property {number|null} [pageSize] ForgotPasswordFilterModel pageSize
         */

        /**
         * Constructs a new ForgotPasswordFilterModel.
         * @memberof ForgotPasswordFilter
         * @classdesc Represents a ForgotPasswordFilterModel.
         * @implements IForgotPasswordFilterModel
         * @constructor
         * @param {ForgotPasswordFilter.IForgotPasswordFilterModel=} [p] Properties to set
         */
        function ForgotPasswordFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ForgotPasswordFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        ForgotPasswordFilterModel.prototype.sortColumn = null;

        /**
         * ForgotPasswordFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        ForgotPasswordFilterModel.prototype.sortOrder = null;

        /**
         * ForgotPasswordFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        ForgotPasswordFilterModel.prototype.pageIndex = null;

        /**
         * ForgotPasswordFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        ForgotPasswordFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ForgotPasswordFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        Object.defineProperty(ForgotPasswordFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ForgotPasswordFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        Object.defineProperty(ForgotPasswordFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ForgotPasswordFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        Object.defineProperty(ForgotPasswordFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ForgotPasswordFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @instance
         */
        Object.defineProperty(ForgotPasswordFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ForgotPasswordFilterModel message. Does not implicitly {@link ForgotPasswordFilter.ForgotPasswordFilterModel.verify|verify} messages.
         * @function encode
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @static
         * @param {ForgotPasswordFilter.IForgotPasswordFilterModel} m ForgotPasswordFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForgotPasswordFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(10).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(18).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(24).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(32).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified ForgotPasswordFilterModel message, length delimited. Does not implicitly {@link ForgotPasswordFilter.ForgotPasswordFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @static
         * @param {ForgotPasswordFilter.IForgotPasswordFilterModel} message ForgotPasswordFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForgotPasswordFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForgotPasswordFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ForgotPasswordFilter.ForgotPasswordFilterModel} ForgotPasswordFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForgotPasswordFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ForgotPasswordFilter.ForgotPasswordFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.sortColumn = r.string();
                    break;
                case 2:
                    m.sortOrder = r.string();
                    break;
                case 3:
                    m.pageIndex = r.int32();
                    break;
                case 4:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a ForgotPasswordFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ForgotPasswordFilter.ForgotPasswordFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ForgotPasswordFilter.ForgotPasswordFilterModel} ForgotPasswordFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForgotPasswordFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ForgotPasswordFilterModel;
    })();

    return ForgotPasswordFilter;
})();

module.exports = $root;
