import FqApi from '@/services/fq-api'

export default {
  async getInsurerSummaries(ecomType, name) {
    if ((ecomType === 'audanet')) {
      const url = `/insurers/loadtask/insurerlist?name=${name}`
      const result = await FqApi.get(url)
      return result.data
    } else {
      const url = 'insurers/insurerlookup'
      const result = await FqApi.post(url, { Name: name, EcomType: ecomType })
      return result.data
    }
  },
  async getQuoteIdByNo(quoteNo) {
    const result = await FqApi.get(`quotes/quoteidbyno?quoteNo=${quoteNo}`)
    return result.data
  },
  async assertCustomerExists(name) {
    const url = `/ormmessages/load/customerexists?name=${encodeURIComponent(name)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getCustomerSummaries(fullname) {
    const url = `/customers/loadquote/customerlist?fullname=${fullname}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteSummaries(rego, claimno, isrepx) {
    //const url = `/quotes/loadquote/quotelist?rego=${rego}&claimmo=${claimno}`
    if (isrepx == undefined || isrepx == null) isrepx = false
    const url = `/quotes/assignquote/${rego}/${claimno}/${isrepx}`
    const result = await FqApi.get(url)
    return result.data
  }
}
