import RepxService from './RepxService'
import OrmBatchingRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'
//import router from '@/router'
// import { nowDate } from '@/components/utils/DateTimeFunctions'

const tag = 'repx'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  messageSummary: {}
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  messageSummary: state => state.messageSummary
}

const actions = {
  async getStoreItem({ commit }, id) {
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      const data = await RepxService.getEntity(id, hash)
      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.batchId, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await RepxService.getEntity(id, ''))
    StoreUtil.setStorage(entity.batchId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(OrmBatchingRoutes.OrmBatchingListView.path, entity.batchId, false)
  },
  async addStoreItem({ commit }) {
    const entity = await RepxService.getNewEntity()
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.batchDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.batchId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(OrmBatchingRoutes.OrmBatchingListView.path, entity.batchId, entity.isNew)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
