import { DateTime, IANAZone } from 'luxon'
import store from '@/store'

const DATE_FULL = DateTime.DATE_FULL
const DATE_SHORT = DateTime.DATE_SHORT
const DATE_SHORT_WITHOUT_YEAR = { day: 'numeric', month: 'numeric' }
const WEEKDAY_SHORT = 'short'
const WEEKDAY_LONG = 'long'

const DATE_FORMAT = {
  DATE_FULL,
  DATE_SHORT,
  DATE_SHORT_WITHOUT_YEAR,
  WEEKDAY_SHORT,
  WEEKDAY_LONG
}

const company = store.getters['company/company']

function customFormatDateLocale(value, locale, options) {
  const date = new Date(Date.parse(`${value}`))
  if (date && !isNaN(date)) {
    if (Intl) {
      return Intl.DateTimeFormat(locale, options).format(date)
    } else {
      return date.toLocaleDateString(locale, options)
    }
  } else {
    return value
  }
}

function formatDateLocale(value, locale, format = '2-digit') {
  const date = new Date(Date.parse(`${value}`))
  if (date && !isNaN(date)) {
    const options = { year: 'numeric', month: format, day: '2-digit' }
    if (Intl) {
      return Intl.DateTimeFormat(locale, options).format(date)
    } else {
      return date.toLocaleDateString(locale, options)
    }
  } else {
    return value
  }
}

function formatDateTimezone(value, locale, format = '2-digit') {
  const date = new Date(Date.parse(`${value}`))
  if (date && !isNaN(date)) {
    const timeZone = company && company.info ? company.info.timezoneIana : Intl.DateTimeFormat().resolvedOptions().timeZone
    const options = { year: 'numeric', month: format, day: '2-digit', timeZone: timeZone }
    if (Intl) {
      return Intl.DateTimeFormat(locale, options).format(date)
    } else {
      return date.toLocaleDateString(locale, options)
    }
  } else {
    return value
  }
}

/**
 *
 * @param {Date, String} value Date value
 * @param {String} locale Region locale e.g. 'en-au'
 * @param {String} weekdayFormat Weekday data format. Valid value short, long
 * @param {String} dateFormat Luxon date format e.g. DATE_SHORT, DATE_FULL.
 * @returns {String} e.g. Tue, 19/04/2022 or Tue, 19/04, Friday, 6 May 2022
 */
function formatWeekDayDateLocale(value, locale, weekdayFormat = WEEKDAY_SHORT, dateFormat = DATE_SHORT) {
  const date = new Date(Date.parse(`${value}`))
  if (date && !isNaN(date)) {
    const luxDate = DateTime.fromJSDate(date)
    const format = { locale: locale, weekday: weekdayFormat, ...dateFormat }
    return luxDate.toLocaleString(format)
  } else {
    return value
  }
}

/**
 * Use for UTC dates where there is no ending "Z"
 * e.g. DateTime from stored procedure for listing pages
 * @param {Date, String} value
 * @param {String} locale
 * @param {String} format
 */
function formatDateUtc(value, locale, format = '2-digit') {
  const date = new Date(Date.parse(`${value}Z`))
  if (date && !isNaN(date)) {
    const options = { year: 'numeric', month: format, day: '2-digit' }
    if (Intl) {
      return Intl.DateTimeFormat(locale, options).format(date)
    } else {
      return date.toLocaleDateString(locale, options)
    }
  } else {
    return value
  }
}

function formatTimeLocale(value, locale, showSecond = false) {
  const date = new Date(Date.parse(value))
  if (date && !isNaN(date)) {
    if (Intl) {
      const options = {
        hour: 'numeric',
        minute: 'numeric', // second: 'numeric',
        hour12: true,
        timeZone: company && company.info ? company.info.timezoneIana : Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      if (showSecond) {
        Object.assign(options, { second: 'numeric' })
      }
      return Intl.DateTimeFormat(locale, options).format(date)
    } else {
      return date.toLocaleTimeString(locale)
    }
  } else {
    return value
  }
}

function formatDateTimeLocale(value, locale, showSecond = false) {
  const date = new Date(Date.parse(value))
  if (date && !isNaN(date)) {
    if (Intl) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric', // second: 'numeric',
        hour12: true,
        timeZone: company && company.info ? company.info.timezoneIana : Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      if (showSecond) {
        Object.assign(options, { second: 'numeric' })
      }
      return Intl.DateTimeFormat(locale, options).format(date)
    } else {
      return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}`
    }
  } else {
    return value
  }
}

function formatDateFull(value, format) {
  const date = new Date(Date.parse(value))
  const datetime = DateTime.fromJSDate(date)
  return datetime.toLocaleString(DateTime.DATE_FULL)
}

function formatDateLong(value) {
  var dt = DateTime.fromISO(value)
  return dt.toFormat('ccc, d MMM yyyy')
}

function firstDayOfMonth(year = null, month = null) {
  const today = new Date()
  const _year = year ? year : today.getFullYear()
  const _month = month ? month : today.getMonth()
  return new Date(_year, _month, 1)
}

function lastDayOfMonth(year = null, month = null) {
  const today = new Date()
  const _year = year ? year : today.getFullYear()
  const _month = month ? month : today.getMonth()
  const result = new Date(_year, _month, 1)
  return result.setDate(result.getDate() - 1)
}

/**
 *
 * @returns {int} Timezone offset of company/site
 */
function getTimezoneOffset() {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // const timezone = new IANAZone(company.info.timezoneIana)
  const timezone = new IANAZone(browserTimezone)
  return timezone.offset(0) / 60
}

function getSiteTimezoneOffset() {
  const timeZone = company && company.info ? company.info.timezoneIana : Intl.DateTimeFormat().resolvedOptions().timeZone
  // const timezone = new IANAZone(company.info.timezoneIana)
  const ianaTimezone = new IANAZone(timeZone)
  return ianaTimezone.offset(DateTime.utc()) / 60

}

function getIanaTimezone() {
  if (company && company.info && company.info.timezoneIana) {
    return company.info.timezoneIana
  } else {
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }
}

/**
 *
 * @param {*} dateObject JS date object to update
 * @param {*} isoDate ISO date string
 * @returns JS date object with current time
 */
function fromISOWithCurrentTime(isoDate) {
  const selected = DateTime.fromISO(isoDate)
  const current = new Date()
  const selectedWithCurrentTime = selected.set({ hour: current.getHours() }).set({ minutes: current.getMinutes()}).set({ seconds: current.getSeconds() })
  const dateObject = selectedWithCurrentTime.toJSDate()
  return dateObject
}

function formatISOToString(value, locale, format) {
  let luxonDateTime = formatISOToLuxon(value, locale)
  if (format)
    return luxonDateTime.toFormat(format)
  return luxonDateTime.toLocaleString(DateTime.DATETIME_FULL)
}
function fromISOToJsDate(value, locale) {
  let luxonDateTime = formatISOToLuxon(value, locale)
  return luxonDateTime.toJSDate()
}
function formatISOToLuxon(value, locale) {
  let luxonDateTime = DateTime.fromISO(value)
  if (!luxonDateTime.isValid)
    return value
  const timeZone = getIanaTimezone()
  if (timeZone) luxonDateTime = luxonDateTime.setZone(timeZone)
  if (locale) luxonDateTime = luxonDateTime.setLocale(locale)
  return luxonDateTime
}

function fromJsDateAdjustedToISO(date) {
  let luxonDateTime = DateTime.fromJSDate(date)
  const timeZone = getIanaTimezone()
  if (timeZone) luxonDateTime = luxonDateTime.setZone(timeZone)
  let adjustedDateTime = luxonDateTime.minus({ minutes: luxonDateTime.offset })
  let utcDate = adjustedDateTime.toUTC().toISO()
  return utcDate
}
function fromJsDateToISO(date) {
  let luxonDateTime = DateTime.fromJSDate(date)
  let utcDate = luxonDateTime.toUTC().toISO()
  return utcDate
}

export {
  customFormatDateLocale,
  formatDateLocale,
  formatWeekDayDateLocale,
  formatTimeLocale,
  formatDateTimeLocale,
  formatDateUtc,
  formatDateFull,
  formatDateLong,
  DATE_FORMAT,
  firstDayOfMonth,
  lastDayOfMonth,
  getTimezoneOffset,
  getIanaTimezone,
  formatDateTimezone,
  getSiteTimezoneOffset,
  fromISOWithCurrentTime,
  formatISOToString,
  fromISOToJsDate,
  formatISOToLuxon,
  fromJsDateAdjustedToISO,
  fromJsDateToISO
}
