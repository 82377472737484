import FqApi from '@/services/fq-api'

export default {
  async getCapacityDueInOut(isDueOut, dateFrom, dateTo, sortColumn, sortOrder) {
    const result = await FqApi.get('/capacity/getDueInOutCapacity' + '?isDueOut=' + isDueOut + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&sortColumn=' + sortColumn + '&sortOrder=' + sortOrder)
    return result.data
  },
  async getBookings() {
    const result = await FqApi.get('/booking/getBookings')
    return result.data
  },
  getNewEntity() {
    return {}
  },
  getEntity(id, hash) {
    return {}
  },
  async getWebServiceTransactions(filter) {
    let url = '/repx/list/'
    const result = await FqApi.post(url, filter)
    return result.data
  },
  async getWebServiceDetail(id) {
    const res = await FqApi.get(`/repx/message/${id}`)
    return res.data
  },
  async getIsRepxEnable() {
    const result = await FqApi.get('/repx/getIsRepxEnable')
    return result.data
  },
  async getRepxRepairStageSettings(repairStage) {
    const result = await FqApi.get(`/repx/getRepxRepairStageSettings?repairStage=${repairStage}`)
    return result.data
  }
}