<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow"><sub-job-stage-list-side-menu v-model="filter"
        @filter="filterList"
        @reset="resetList" /></div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(entity, index) in entitySummaries"
                :key="entity.subJobStageId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)"
              >
                <td>{{ entity.jobStageName }}</td>
                <td>
                  <a @click="handleEdit(entity.subJobStageId)">
                    {{ entity.subJobStageName }}
                  </a>
                </td>
                <td>{{ entity.sortKey }}</td>
                <td class="has-text-centered">
                  <span
                    class="icon"
                    :class="[ entity.isActive ? 'has-text-success' : 'has-text-danger' ]">
                    <i
                      class="mdi mdi-24px"
                      :class="[ entity.isActive ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <a
                    class="button is-primary is-small is-inverted tooltip"
                    @click="handleEdit(entity.subJobStageId)"
                    data-tooltip="Click to edit">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article></div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import SubJobStageListSideMenu from './SubJobStageListSideMenu.vue'
import SubJobStageService from './SubJobStageService'
import SubJobStageValidation from './SubJobStageValidation'

export default {
  name: 'SubJobStageListView',
  components: {
    AppDetailHeader,
    BulmaTable,
    SubJobStageListSideMenu
  },
  mixins: [StoreMixin, SubJobStageValidation],
  props: {
  },
  data:()=> {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        jobStageName: '',
        subJobStageName: '',
        sortColumn: Columns[2].name,
        sortOrder: Columns[2].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getData()
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
      }
    },
    async addNew() {
      this.addStoreItem()
    },
    async getData() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await SubJobStageService.getSubJobStages(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Sub Job Stage', 'Filter validation errors.')
        return false
      }
       this.filter.pageIndex = 1
       this.persistFilter()
       this.getData()
    },
    resetList() {
      this.filter.jobStageName = ''
      this.filter.subJobStageName = ''
      this.filter.sortColumn = Columns[2].name
      this.filter.sortOrder = Columns[2].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
       this.filter.sortColumn = name
       this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    handleEdit(id) {
      this.$router.push({
        name: 'SubJobStageDetail',
        params: { subJobStageId: id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    }
  }
}
</script>
