import { required } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.jobStage', 'entity.subJobStageName'],
    entity: {
      jobStage: {
        required
      },
      subJobStageName: {
        required
      },
      sortKey:{
        required
      }
    },
    filter: {}
  }
}
